import {useEffect, useState} from "react";
import {useParams, useLocation, useNavigate, Navigate} from "react-router-dom";
import apiClient from "../../utils/axiosConfig";
import Pagination from "../../components/Pagination";

const ChatMessagesPage = () => {

    const [messages, setMessages] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [currPage, setCurrPage] = useState(1)
    const [sort, setSort] = useState('')
    const [sortType, setSortType] = useState('')
    const perPage = 12
    const navigate = useNavigate()
    const location = useLocation()
    const {chat_id: chatId} = useParams()
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page = parseInt(searchParams.get('page') || 1, 10)
        const sortParam = searchParams.get('sort') || ''
        const sortTypeParam = searchParams.get('sort_type') || ''

        setCurrPage(page)
        setSort(sortParam)
        setSortType(sortTypeParam)

        const fetchData = async () => {
            try {
                const response = await apiClient.get('/fs_message/list', {
                    params: {
                        chat_id: chatId,
                        page: currPage,
                        page_size: perPage
                    }
                })
                setMessages(response.data.items)
                setTotal(response.data.total)
                setLoading(false)
            } catch (err) {
                setError(err)
                setLoading(false)
            }
        }
        fetchData()

        document.title = `fsMessages chat_id:${chatId}`
    }, [location.search]);

    const pagination = (n) => {
        navigate(`?page=${n}`)
    }

    const handleSortUpdate = ({sort: _sort, sortType: _sortType}) => {
        setSort(_sort);
        setSortType(_sortType);
        navigate(`?page=${currPage}&sort=${sort}&sort_type=${_sortType}`)
    };

    const isValidChatId = /^oc_[a-f0-9]{32}$/.test(chatId)
    if (!isValidChatId) {
        console.log('isValidChatId')
        return <Navigate to="/404"/>
    }

    if (loading) {
        return <p>Loading...</p>
    }
    if (error) {
        return <p>{error}</p>
    }
    return (
        <>
            <h1>ChatMessages for {chatId}</h1>
            <div>
                {messages.length === 0 ? (
                    <p>no data</p>
                ) : (
                    <div>
                        {messages.map((item, index) => (
                            <div key={index} className="border-bottom py-2">
                                <b>{item.sender}</b>
                                <pre>{item.content}</pre>
                            </div>
                        ))}
                    </div>
                )}

            </div>

            <Pagination
                perPage={perPage}
                total={total}
                paginate={pagination}
                currentPage={currPage}
            />
        </>
    )
}

export default ChatMessagesPage;