import React from "react";
const PostComments = ({comments}) => {
    if(!comments || comments.length === 0) {
        return null;
    }

    return (
        <ul className="list-group list-group-flush small">
            {comments.map((comment,index) => (
                <li key={index} className="list-group-item">
                    <b className="text-muted">{comment.author}</b>
                    <span dangerouslySetInnerHTML={{__html: comment.content}}></span>
                    <em className="small">{comment.comment_at}</em>
                </li>
            ))}
        </ul>
    )
}

export default PostComments;