import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

function NotFoundPage() {
    const navigate = useNavigate()
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/")
        }, 5000)
    }, [navigate]);

    return (
        <div className="alert alert-danger">
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <p>
                <Link to="/?from404">Redirecting to the homepage in 5 seconds...</Link>
            </p>
        </div>
    )
}

export default NotFoundPage;