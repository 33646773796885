import React from 'react';
import {useNavigate} from "react-router-dom";
import {formatNumber} from "../utils/format";

const Pagination = ({perPage, total, paginate, currentPage}) => {
    const pageNumbers = [];
    const navigate = useNavigate()
    const totalPages = Math.ceil(total / perPage)
    const maxShow = 5; // 最多显示5个页码
    let startPage = Math.max(1, currentPage - Math.floor(maxShow / 2))
    let endPage = Math.min(totalPages, startPage + maxShow - 1)
    if (endPage - startPage + 1 < maxShow) {
        startPage = Math.max(1, endPage - maxShow + 1)
    }
    // 计算总页数
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (n) => {
        paginate(n)
        navigate(`?page=${n}`)
    }

    return (
        <nav>
            <p className="text-start small">Total: {formatNumber(total)}</p>
            <ul className="pagination justify-content-end">
                {/*始终显示第一页*/}
                <li className="page-item">
                    <a href="#!" className="page-link"
                       onClick={() => handlePageClick(1)}>
                        &laquo;
                    </a>
                </li>
                {/* 如果当前页大于1，显示上一页 */}
                {currentPage > 1 && (
                    <li className="page-item">
                        <a href="#!" className="page-link"
                           onClick={() => handlePageClick(currentPage - 1)}>
                            上一页
                        </a>
                    </li>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <a onClick={() => handlePageClick(number)} href="#!" className="page-link">
                            {number}
                        </a>
                    </li>
                ))}

                {/* 如果当前小于总页数，显示下一页 */}
                {currentPage < totalPages && (
                    <li className="page-item">
                        <a href="#!" className="page-link"
                           onClick={() => handlePageClick(currentPage + 1)}>
                            下一页
                        </a>
                    </li>
                )}
                {/* 始终显示末页 */}
                <li className="page-item">
                    <a href="#!" className="page-link"
                       onClick={() => handlePageClick(totalPages)}>
                        &raquo;
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
