import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, Route, Navigate, Routes, useLocation} from "react-router-dom";
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';


import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.min.css';

import ThreadsPage from "./pages/ThreadsPage";
import ThreadDetailPage from './pages/ThreadDetailPage';
import Nav from "./components/Nav";
import NotFoundPage from "./pages/NotFoundPage";
import ChatsPage from "./pages/fs_chat/ChatsPage";
import ChatMessagesPage from "./pages/fs_chat/ChatMessagesPage";
import ChatMembersPage from "./pages/fs_chat/ChatMembersPage";
import LogoutPage from "./pages/LogoutPage";
import QQFriends from "./pages/qqdb/QQFriends";
import QQNicklogs from "./pages/qqdb/QQNicklogs";
import QQGroups from "./pages/qqdb/QQGroups";


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const checkLoginStatus = () => {
            const token = localStorage.getItem('jwt')
            if (token) {
                setIsLoggedIn(true)
            }

            setLoading(false)
        }

        checkLoginStatus()
    }, [])

    if (loading) {
        return <p>loading...</p>
    }

    return (
        <Router>
            <AppRouter isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
        </Router>
    )
}

function AppRouter({isLoggedIn, setIsLoggedIn}) {
    const location = useLocation()
    const showNav = ['/404', '/logout', '/login'].some(path => location.pathname.startsWith(path)) === false
    const routes = isLoggedIn ? (
        <>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/threads/:id" element={<ThreadDetailPage/>}/>
            <Route path="/threads" element={<ThreadsPage/>}/>
            <Route path="/qqdb/friends" element={<QQFriends/>}/>
            <Route path="/qqdb/nicklogs" element={<QQNicklogs/>}/>
            <Route path="/qqdb/groups" element={<QQGroups/>}/>
            <Route path="/fs_chat/chats" element={<ChatsPage/>}/>
            <Route path="/fs_chat/:chat_id/members" element={<ChatMembersPage/>}/>
            <Route path="/fs_chat/:chat_id/messages" element={<ChatMessagesPage/>}/>
        </>
    ) : (
        <>
            <Route path="*" element={<Navigate to="/login"/>}/>
        </>
    )
    return (
        <div className="container-fluid">
            {showNav && <Nav/>}
            <Routes>
                {routes}
                <Route path="/logout" element={<LogoutPage setIsLoggedIn={setIsLoggedIn}/>}/>
                <Route path="/login"
                       element={isLoggedIn ? <Navigate to="/"/> : <LoginPage setIsLoggedIn={setIsLoggedIn}/>}/>
                <Route path="*" element={<Navigate to="/404"/>}></Route>
                <Route path="/404" element={<NotFoundPage/>}></Route>
            </Routes>
        </div>
    );
}

export default App;
