import React from "react";
import {useNavigate,Link} from 'react-router-dom';

const HomePage = () => {
    return (
        <div>
            <h1>欢迎来到首页</h1>
            <p>welcome</p>
        </div>
    )
}

export default HomePage;