import {Link} from "react-router-dom";

function Nav() {
    return (<>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">Post-Review</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">首页</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/threads">论坛帖子</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/qqdb/friends">QQDB</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/fs_chat/chats" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                飞书群
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/fs_chat/chats">群列表</Link></li>
                                <li><Link className="dropdown-item" to="#">成员列表</Link></li>
                                <li><Link className="dropdown-item" to="#">主题列表</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item d-flex flex-row-reverse justify-content-end">
                            <Link className="nav-link" to="/logout">Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>)
}

export default Nav;