import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,// 后端api基础URL
    headers: {
        'Content-Type': 'application/json'
    }
})

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwt');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
})

apiClient.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default apiClient;