import React, {useState} from 'react';
import apiClient from "../utils/axiosConfig";
const LoginPage = ({setIsLoggedIn}) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('')
    const handleLogin = async () => {
        try {
            const response = await apiClient.post('/login', {code})
            const {code: respCode} = response;
            if (respCode === 0) {
                const {jwt} = response.data
                localStorage.setItem('jwt', jwt)
                setIsLoggedIn(true)
            } else {
                const {msg: respMsg} = response
                setError(`登陆失败：${respMsg}`)
            }
        } catch (err) {
            setError(`登陆失败，code不对${err}`)
        }
    };

    return (
        <div>
            <h2>登录</h2>
            <input
                type="text"
                placeholder="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
            />
            <button onClick={handleLogin}>登陆</button>
            {error && <p>{error}</p>}
        </div>
    )
}

export default LoginPage;