import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import apiClient from "../../utils/axiosConfig";
import Pagination from "../../components/Pagination";

const QQFriends = () => {
    const [friends, setFriends] = useState()
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [currPage, setCurrPage] = useState(0)
    const [sort, setSort] = useState('')
    const [sortType, setSortType] = useState('')
    const perPage = 12;
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page = parseInt(searchParams.get('page') || 1, 10)
        const sortParam = searchParams.get('sort') || ''
        const sortTypeParam = searchParams.get('sort_type') || ''

        setCurrPage(page)
        setSort(sortParam)
        setSortType(sortTypeParam)

        const fetchData = async () => {
            try {
                const response = await apiClient.get('/qqdb/friends', {
                    params: {
                        page: currPage,
                        page_size: perPage,
                    }
                })

                setFriends(response.data.items)
                setTotal(response.data.total)
                setLoading(false)
            } catch (err) {
                setError(err)
                setLoading(false)
            }
        }

        fetchData()

    }, [location.search]);

    const pagination = (n) => {
        navigate(`?page=${n}`)
    }

    const handleSortUpdate = ({sort: _sort, sortType: _sortType}) => {
        setSort(_sort)
        setSortType(_sortType)
        navigate(`?page=${currPage}&sort=${sort}&sort_type=${_sortType}`)
    }

    if(loading){
        return <p>loading...</p>
    }

    if(error){
        return <p>{error}</p>
    }

    return (
        <>
            <h1>QQ Friends</h1>
            <div>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>QQ</th>
                        <th>昵称</th>
                        <th>加群数</th>
                        <th>昵称数</th>
                    </tr>

                    </thead>
                    <tbody>
                    {friends.length === 0 ?(
                        <p>no data</p>
                    ):(friends.map((item,index) => (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.uin}</td>
                            <td>{item.nick}</td>
                            <td>
                                <Link to={`/qqdb/groups?qq=${item.uin}`}>{item.group_num}</Link>
                            </td>
                            <td>
                                <Link to={`/qqdb/nicklogs?qq=${item.uin}`}>{item.nick_num}</Link>
                            </td>
                        </tr>
                    )))}
                    </tbody>
                </table>
                <Pagination perPage={perPage} total={total}  paginate={pagination} currentPage={currPage} />
            </div>
        </>
    )
}
export default QQFriends;