import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import apiClient from "../utils/axiosConfig";
import Pagination from "../components/Pagination";
import PostComments from "../components/PostComments";
import PostRates from "../components/PostRates";

const ThreadDetailPage = () => {
    const {id} = useParams()
    const [thread, setThread] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [currPage, setCurrPage] = useState(1);
    // const [title, setTitle] = useState('');

    const perPage = 12;
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await apiClient.get(`/threads/${id}`, {
                    params: {
                        page: currPage,
                        page_size: perPage
                    }
                });
                setThread(response.data)
                setLoading(false)
                document.title = `[${response.data.post_at.substring(0, 4)}] ${response.data.title}`
            } catch (err) {
                setError(err.message)
                setLoading(false)
            }
        }
        fetchData()
    }, [id, currPage, perPage]);

    // const indexOfLastPost = currPage * perPage
    // const indexOfFirstPost = indexOfLastPost - perPage
    const paginate = (pageNumber) => setCurrPage(pageNumber)
    if (loading) {
        return <p>加载中...</p>
    }

    if (error) {
        return <p>出现错误：{error}</p>
    }
    return (
        <div>
            {thread ? (
                    <div>
                        <h1>[{thread.post_at.substring(0, 4)}] {thread.title}</h1>
                        <a href={thread.outer_url} target="_blank" className="badge link-primary" rel="noopener noreferrer">{ thread.outer_url }</a>
                        <div>
                            <b>{thread.site}:{thread.tid}</b> Author: <b>{thread.author}</b> Hit:{thread.hit} Reply:{thread.reply}
                        </div>
                        {Array.isArray(thread.posts) && thread.posts.map((post) => (
                            !post.deleted && (
                                <div key={post.id} className="py-2">
                                    <p>
                                        #{post.sortid} <b>{post.author}</b>
                                        发表于 {new Date(post.post_at).toLocaleString()}
                                        {(post.author !== 'Anonymous' || post.post_at === thread.post_at) && post.author === thread.author && (
                                            <span className="badge bg-info">楼主</span>
                                        )}
                                    </p>
                                    <div className="lead">
                                        <div dangerouslySetInnerHTML={{__html: post.content}}/>
                                        <PostRates rates={post.rates}/>
                                        <PostComments comments={post.comments}/>
                                        {post.deleted ? <em className="text-danger">已屏蔽</em> : ''}
                                        {post.content.length > 100 && (
                                            <span className="badge bg-success">字数{post.content.length}</span>)}
                                    </div>
                                    <hr/>
                                </div>
                            )
                        ))}
                        {/* 使用分页组件 */}
                        <Pagination
                            perPage={perPage}
                            total={thread.post_total}
                            paginate={paginate}
                            currentPage={currPage}
                        />
                    </div>
                ) :
                (
                    <p>未找到帖子</p>
                )
            }

        </div>
    )
}

export default ThreadDetailPage;