import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";

const LogoutPage = ({setIsLoggedIn}) => {
    const navigate = useNavigate()
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/login")
            clearTimeout(timer)
        },5000)
    }, [navigate]);
    const handleLogout = () => {
        localStorage.removeItem('jwt')
        setIsLoggedIn(false)
    }

    handleLogout()
    return (
        <div className="alert alert-success">
            <h1>Logout success</h1>
            <p>
                <Link to="/login">click here to contine...</Link>
            </p>
        </div>
    )
}

export default LogoutPage;