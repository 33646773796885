import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import apiClient from "../../utils/axiosConfig";


const ChatMembersPage = () => {
    const [members, setMemebers] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [currPage, setCurrPage] = useState(1)
    const [sort, setSort] = useState('')
    const [sortType, setSortType] = useState('')
    const perPage = 12
    const navigate = useNavigate()
    const location = useLocation()
    const {chat_id: ChatId} = useParams()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page = parseInt(searchParams.get('page') || 1, 10)
        const sortParam = searchParams.get('sort') || ''
        const sortTypeParam = searchParams.get('sort_type') || ''


        setCurrPage(page)
        setSort(sortParam)
        setSortType(sortTypeParam)
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/fs_chat/members', {
                        params: {
                            chat_id: ChatId
                        }
                    }
                )
                setMemebers(response.data.items)
                setTotal(response.data.total)
            } catch (err) {
                setError(err)
            }
        }

    }, [location.search]);
    return (
        <></>
    )
}

export default ChatMembersPage;