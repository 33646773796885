import React from "react";

const PostRates = ({rates}) => {
    if (!rates || rates.length === 0) {
        return null;
    }
    return (
        <ul>
            {rates.map((rate,index) => (
                <li key={index} className="badge bg-light">
                    <b>{rate.author}</b> <span dangerouslySetInnerHTML={{__html:rate.content}}></span>
                    <small>{rate.rate_at}</small>
                </li>
            ))}
        </ul>
    )
}

export default PostRates;