import React, {useEffect, useState} from "react";
import apiClient from "../../utils/axiosConfig";
import {formatNumber, formatDate} from "../../utils/format";
import Pagination from "../../components/Pagination";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Sorting from "../../components/Sorting";

const ChatsPage = () => {
    const [chats, setChats] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [currPage, setCurrPage] = useState(1)
    const [sort, setSort] = useState('');
    const [sortType, setSortType] = useState('')
    const perPage = 12;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page = parseInt(searchParams.get('page') || 1, 10);
        const sortParam = searchParams.get('sort') || '';
        const sortTypeParam = searchParams.get('sort_type') || ''

        // console.log('sortParam = ',sortParam)
        // console.log('sortTypeParam = ',sortTypeParam)

        if (page !== currPage) {
            setCurrPage(page)
        }
        if (sortParam !== sort) {
            setSort(sortParam)
        }
        if (sortTypeParam !== sortType) {
            setSortType(sortTypeParam)
        }
        document.title = `fsChats PAGE:${formatNumber(page)}`
    }, [currPage, sort, sortType, location.search]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await apiClient.get('/fs_chat/list', {
                    params: {
                        page: currPage,
                        page_size: perPage,
                        sort: sort,
                        sort_type: sortType
                    }
                })
                setChats(response.data.items)
                setTotal(response.data.total)
                setLoading(false)
            } catch (err) {
                // console.log('err fetch ', err)
                setError(err)
                setLoading(false)
            }
        }
        fetchData()
    }, [currPage, perPage, sort, sortType]);

    // useEffect(() => {
    // console.log('Current sort:', sort);
    // console.log('Current sortType:', sortType);
    // }, [sort, sortType]);

    const pagination = (n) => {
        navigate(`?page=${n}`)
    }

    const handleSortUpdate = ({sort: _sort, sortType: _sortType}) => {
        // console.log('Current sort:', sort, 'New sort:', _sort);
        // console.log('Current sortType:', sortType, 'New sortType:', _sortType);
        setSort(_sort);
        setSortType(_sortType);
        navigate(`?page=${currPage}&sort=${sort}&sort_type=${_sortType}`)
    };

    if (loading) {
        return <p>加载中...</p>
    }

    if (error) {
        return <p>出现错误：{error}</p>
    }

    return (
        <>
            <h1>Chats {formatNumber(total)}</h1>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>ID
                        <Sorting name="id" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>群名
                        <Sorting name="name" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>成员
                        <Sorting name="member_num" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>消息
                        <Sorting name="message_num" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>创建
                        <Sorting name="build_at" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>入库
                        <Sorting name="created_at" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {chats.map(chat => (
                    <tr key={chat.id}>
                        <td>{chat.id}</td>
                        <td>
                            <Link to={`/fs_chat/${chat.chat_id}/messages`}>
                                {chat.name}
                            </Link>
                        </td>
                        <td>
                            <Link to={`/fs_chat/${chat.chat_id}/members`}>
                                {formatNumber(chat.member_num)}
                            </Link>
                        </td>
                        <td>
                            <Link to={`/fs_chat/${chat.chat_id}/messages`}>
                                {formatNumber(chat.message_num)}
                            </Link>
                        </td>
                        <td>{formatDate(chat.build_at)}</td>
                        <td>{formatDate(chat.created_at)}</td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <th></th>
                </tr>
                </tfoot>
            </table>

            <Pagination
                perPage={perPage}
                total={total}
                paginate={pagination}
                currentPage={currPage}
            />
        </>
    )
}

export default ChatsPage;