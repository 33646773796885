import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import apiClient from "../utils/axiosConfig";
import Pagination from "../components/Pagination";
import Sorting from "../components/Sorting";
import {formatNumber, formatDate} from "../utils/format";

const ThreadsPage = () => {
    const [threads, setThreads] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [currPage, setCurrPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState('')
    const [sortType, setSortType] = useState('');
    const perPage = 12;
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page = searchParams.get('page') || 1;
        const sortParam = searchParams.get('sort') || ''
        const sortTypeParam = searchParams.get('sort_type') || '';
        setCurrPage(parseInt(page, 10))
        setSort(sortParam)
        setSortType(sortTypeParam)

        document.title = `帖子列表 PAGE:${formatNumber(page)}`
    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get('/threads', {
                    params: {
                        page: currPage,
                        page_size: perPage,
                        order: sort,
                        order_type: sortType
                    }
                })
                setThreads(response.data.items)
                setTotal(response.data.total)
                setLoading(false)
            } catch (err) {
                setError(err.message)
                setLoading(false)
            }
        }
        fetchData();
    }, [currPage, perPage, sort, sortType]);
    const paginate = (n) => {
        navigate(`?page=${n}`)
    }

    const handleSortUpdate = ({_sort, _sortType}) => {
        setSort(_sort)
        setSortType(_sortType)
    }
    if (loading) {
        return <p>加载中...</p>
    }

    if (error) {
        return <p>出现错误：{error}</p>
    }

    return (
        <div>
            <h1>帖子列表</h1>
            <table className="table table-hover table-responsive">
                <thead>
                <tr>
                    <th>ID
                        <Sorting name="id" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>标题
                        <Sorting name="title" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>站点
                        <Sorting name="site" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>作者
                        <Sorting name="author" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th>发布于
                        <Sorting name="post_at" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th className="text-end">浏览
                        <Sorting name="hit" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                    <th className="text-end">回帖
                        <Sorting name="reply" curr={sort} sort_type={sortType} onUpdate={handleSortUpdate}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {Array.isArray(threads) && threads.length > 0 ? (
                    threads.map(thread => (
                        <tr key={thread.id}>
                            <td>{thread.id}</td>
                            <td>
                                <Link to={`/threads/${thread.id}`}>{thread.title}</Link>
                            </td>
                            <td>{thread.site}</td>
                            <td>{thread.author}</td>
                            <td>{formatDate(thread.post_at)}</td>
                            <td className="text-end">{formatNumber(thread.hit)}</td>
                            <td className="text-end">{formatNumber(thread.reply)}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="9">没有数据</td>
                    </tr>
                )}
                </tbody>
            </table>
            {/* 使用分页组件 */}
            <Pagination
                perPage={perPage}
                total={total}
                paginate={paginate}
                currentPage={currPage}
            />
        </div>
    )
}

export default ThreadsPage;