import React from 'react';

const Sorting = ({name, curr, sort_type, onUpdate}) => {
    const handleSortClick = (sortType) => {
        if (curr === name && sort_type === sortType) return;
        onUpdate({sort: name, sortType: sortType});
    }
    // @todo bug处理，只点击一次，这里却被调用了很多次
    // console.log('name = ',name,curr,sort_type)
    return (
        <>
            {/*默认排序图标，如果没有排序时显*/}
            {curr !== name ? (
                <a href="#" onClick={() => handleSortClick('desc')}>
                    <i className="bi bi-filter"></i>
                </a>
            ) : null}

            {/*升序图标*/}
            {curr === name && sort_type !== 'desc' ? (
                <a href="#" onClick={() => handleSortClick('desc')}>
                    <i className="bi bi-sort-up-alt"></i>
                </a>
            ) : null}

            {/*降序图标*/}
            {curr === name && sort_type === 'desc' ? (
                <a href="#" onClick={() => handleSortClick('asc')}>
                    <i className="bi bi-sort-down-alt"></i>
                </a>
            ) : null}
        </>
    )
}

export default Sorting;